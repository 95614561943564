/* teamtalk javascripting */

require('webpack-jquery-ui/droppable');
require('webpack-jquery-ui/css');  //ommit, if you don't want to load basic css theme

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";

var pageInitialized = false;

(function () {
    if ($) {
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', token);
            }
        });
    }
})();

$.expr[':'].contains = function (a, i, m) {
    return $(a).text().toUpperCase()
        .indexOf(m[3].toUpperCase()) >= 0;
};

function JSON_to_URLEncoded(element, key, list) {
    var list = list || [];
    if (typeof (element) == 'object') {
        for (var idx in element)
            JSON_to_URLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list);
    } else {
        list.push(key + '=' + encodeURIComponent(element));
    }
    return list.join('&');
}

window.MeSYS = {
    submitted: 0,
    initial_load: 0,


    getWordCloud() {
    },

    setSchnellsuche() {
        $('.schnellsucheInput').on('keyup', function (e) {
            $(".katcard:not(:contains('" + this.value + "'))").css("display", "none");
            $(".katcard:contains('" + this.value + "')").css("display", "");
        });
    },
    deleteCluster(id) {
        $.ajax({
            url: '/clusters/' + id,
            type: 'DELETE',
            success: function (result) {
                window.location.href = '/smsmode/index';
            }
        });
    },

    lastClusterOpened: false,
    clusterIsOpoen: false,

    displayCluster(id) {
        var otherid = $('button.showoff').attr('data-id');
        console.log("displayCluster id", id);
        console.log("displayCluster otherid", otherid);

        //if (window.MeSYS.clusterIsOpoen == true) {
        //    window.MeSYS.clusterIsOpoen = false;
        //}
        // alle mit showiff anklicken, die NICHT das aktuelle Element sind!!!
        // einer oder kein button
        //$('button.showoff:not([data-id="' + id + '"])').trigger('click'); // das sind immer die anderen buttons per def.

        $.post('/smsmode/setclustersel/' + id, [], function (json) {
            // SMS/Kategorie Daten vom API laden
            console.log("setclustersel: ", json);
            var payload = JSON.parse(json[0]['payload']);
            console.log("setclustersel payload: ", payload);
            var targetmethod = '/smsmode/show' + json[0].name;
            console.log('calling ' + targetmethod);

            //$.get(targetmethod + '?id=' + payload[0].displayrecord[0].id, function (data) {
            //    $('#selectedDisplay').html(data);
            //});
        }, 'json').done(function (data) {
            console.log('setclustersel successfully called');
            //    if (!window.MeSYS.clusterIsOpoen) {
            //        window.MeSYS.clusterIsOpoen = true;
            //        window.MeSYS.lastClusterOpened = id;
            //    }
        }).fail(function (data) {
            console.log('setclustersel call failed', data);
        });
    },

    // window variables cur_id is holding the currently displayed cluster id
    // we take the next item, which is holding the next cluster-id in data-id
    // so the logic is easy: if showoff, disable the cur_id 
    // display the new cur_id

    clusterlast() {
        // v2
        // if there is any activated button, close it first
        // #clusterlist > div.clusterrow.currentItem > div.clusteractions.float-right > button.btn.btn-primary.clusterLosBtn.showoff
        //$('#clusterlist > div.clusterrow.currentItem > div.clusteractions.float-right > button.clusterLosBtn.showoff').trigger('click');
        var last_id = $('#clusterlist .clusterrow.currentItem').attr('data-id');
        console.log('last_id', last_id);
        if ($('#clusterlist .clusterrow.currentItem button.clusterLosBtn').hasClass('showoff')) {
            window.MeSYS.displayCluster(last_id);
        }
        var curItem = $('#clusterlist .clusterrow.currentItem');
        $('#clusterlist .clusterrow').removeClass('currentItem');
        $('#clusterlist .clusterrow').last().addClass('currentItem');
        var cur_id = $('#clusterlist .clusterrow.currentItem').attr('data-id');
        console.log('cur_id', cur_id);
        window.cur_id = cur_id;
        window.MeSYS.displayCluster(cur_id);
    },

    clusterfirst() {
        //first
        console.log('clusterfirst called ');
        var last_id = $('#clusterlist .clusterrow.currentItem').attr('data-id');
        console.log('last_id', last_id);
        if ($('#clusterlist .clusterrow.currentItem button.clusterLosBtn').hasClass('showoff')) {
            window.MeSYS.displayCluster(last_id);
        }
        var curItem = $('#clusterlist .clusterrow.currentItem');
        $('#clusterlist .clusterrow').removeClass('currentItem');
        $('#clusterlist .clusterrow').first().addClass('currentItem');
        var cur_id = $('#clusterlist .clusterrow.currentItem').attr('data-id');
        console.log('cur_id', cur_id);
        window.cur_id = cur_id;
        window.MeSYS.displayCluster(window.cur_id);
    },

    clusternext() {
        var last_id = window.cur_id;
        console.log('last_id', last_id);
        if ($('#clusterlist .clusterrow.currentItem button.clusterLosBtn').hasClass('showoff')) {
            window.MeSYS.displayCluster(window.cur_id); // switch off
        }
        var curItem = $('#clusterlist .clusterrow.currentItem');
        $('#clusterlist .clusterrow').removeClass('currentItem');
        curItem.next().addClass('currentItem');
        window.cur_id = $('#clusterlist .clusterrow.currentItem').attr('data-id');
        console.log('cur_id', window.cur_id);
        window.MeSYS.displayCluster(window.cur_id);
    },
    //
    clusterprev() {
        var last_id = window.cur_id;
        console.log('last_id', last_id);
        if ($('#clusterlist .clusterrow.currentItem button.clusterLosBtn').hasClass('showoff')) {
            window.MeSYS.displayCluster(window.cur_id); // switch off
        }
        var curItem = $('#clusterlist .clusterrow.currentItem');
        $('#clusterlist .clusterrow').removeClass('currentItem');
        curItem.prev().addClass('currentItem');
        window.cur_id = $('#clusterlist .clusterrow.currentItem').attr('data-id');
        console.log('cur_id', window.cur_id);
        window.MeSYS.displayCluster(window.cur_id);
    },
    // cluster navigation end

    setMessageSchnellsuche() {
        $("[autofocus]").on("focus", function () {
            if (this.setSelectionRange) {
                var len = this.value.length * 2;
                this.setSelectionRange(len, len);
            } else {
                this.value = this.value;
            }
        }).focus();
        $('.schnellsucheInput').on('keyup', function (e) {
            $('form#schnellsuche').submit();
        });
    },

    setMiniStat() {
        $.get('/home/loadministat', function (stats) {
            $('.ministat').html(stats);
        });
    },

    showNotification: function () {
        $('.toast').toast();
    },

    reloadAllDevices: function () {
        $.get('/smsmode/reloadalldevices');
    },

    setPRESENTER: function () {
        $.get('/smsmode/setpresenter');
    },

    setSMSVIEWSMS: function () {
        $.get('/smsmode/setsmsviewsms');
    },

    setSMSVIEWKAT: function () {

        $.get('/smsmode/setsmsviewkat');
    },

    updateKategoryCards: function () {
        console.log('updateKategoryCards called!');
        $.get('/kategories', function (kats) {
            console.log('remove kategorycards');
            $('.kategoriePane .kategorycard').remove();
            console.log('adding new kategorycards');
            $('#katadd').before(kats);
            //window.MeSYS.setDragtargets();
            //window.MeSYS.setKategoryForm();
        })
            .fail(function (e) {
                console.log(e.message);
            });
        window.MeSYS.setMiniStat();
    },

    resetKatSmsSelector: function () {
        // selected Display reset, formular reset
        $('#show').unbind('click');
        $('#cancel').unbind('click');
        $('#selectedDisplay').html('');
        $('#smsselection').val('');
        $('option:selected', $('#katselection')).prop('selected', false);
        window.MeSYS.setMiniStat();
    },

    loadKatEdit: function (element) {
        let color = $(element).attr('data-color')
        let name = $(element).attr('data-name');
        let id = $(element).attr('data-id');
        $('option[value=' + color + ']', $('#kategory_color')).prop('selected', true);
        $('#kategory_name').val(name);
        $('#kategory_id').val(id);
        $('#fNumsns').remove();
        $('#fRead').remove();
        $('#fLocked').remove();
        $('#exampleFormModalLabel').text('Kategorie ändern');
        window.MeSYS.setMiniStat();
    },

    setReadKat: function (id) {
        var url;
        url = '/smsmode/setreadkat.json';
        //console.log('before AJAX ' + url);
        //console.log($('#kategoryAddForm').serialize());
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', token);
            }
        });
        $.post(url, { kat_id: id }, function (data) {
            console.log("setReadKat");
            this.resetKatSmsSelector();
        }).done(function (e) {
            console.log(url + ' call done');
        }).fail(function (e) {
            console.log(e);
        });
        //console.log('after ajax');
        window.MeSYS.setMiniStat();

    },


    setUnreadKat: function (id) {
        var url;
        url = '/smsmode/setunreadkat.json';
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', token);
            }
        });
        $.post(url, { kat_id: id }, function (data) {
            console.log("setUnreadKat");
            this.resetKatSmsSelector();
        }).done(function (e) {
            console.log(url + ' call done');
        }).fail(function (e) {
            console.log(e);
        });
        window.MeSYS.setMiniStat();
    },


    setReadMsg: function (id) {
        var url;
        console.log("setReadMsg ", id);

        // allfällige offene Cluster zumachen (die haben die klasse showoff)
        //$('button.showoff').trigger('click'); 

        url = '/smsmode/setreadsms.json';
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', token);
            }
        });
        $.post(url, { id: id }, function (data) {
            this.resetKatSmsSelector();
        }).done(function (e) {
            console.log(url + ' call done');
        }).fail(function (e) {
            console.log(e);
        });
        window.MeSYS.setMiniStat();
    },

    setReadAndShowNextMsg: function (id) {
        var url;
        console.log("setReadMsg ", id);

        url = '/smsmode/setreadsmsandnext.json';
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', token);
            }
        });
        $.post(url, { id: id }, function (data) {
            this.resetKatSmsSelector();
            // ShowNextMessage Magic
            // data.next_sms hat was wir wollen
            alert("Hier!!")
        }).done(function (e) {
            console.log(url + ' call done');
        }).fail(function (e) {
            console.log(e);
        });
        window.MeSYS.setMiniStat();
    },

    toggleReadStatusMsg: function (id) {
        var url;
        url = '/smsmode/togglesms.json';
        console.log("toggleReadStatusMsg ", id);
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', token);
            }
        });
        $.post(url, { id: id }, function (data) {
            this.resetKatSmsSelector();
            if ($('div[data-id=' + data.smsid + '] button').hasClass('btn-danger')) {
                $('div[data-id=' + data.smsid + '] button').removeClass('btn-danger')
                $('div[data-id=' + data.smsid + '] button').addClass('btn-success')
            }
            else {
                $('div[data-id=' + data.smsid + '] button').removeClass('btn-success')
                $('div[data-id=' + data.smsid + '] button').addClass('btn-danger')
            }

        }).done(function (e) {
            console.log(url + ' call done');
        }).fail(function (e) {
            console.log(e);
        });
        window.MeSYS.setMiniStat();

    },

    setUnreadMsg: function (id) {
        var url;
        url = '/smsmode/setunreadsms.json';
        console.log("setUnreadMsg ", id);
        var token = $('meta[name="csrf-token"]').attr('content');

        $.ajaxSetup({
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', token);
            }
        });
        $.post(url, { id: id }, function (data) {
            this.resetKatSmsSelector();
        }).done(function (e) {
            console.log(url + ' call done');
        }).fail(function (e) {
            console.log(e);
        });
        window.MeSYS.setMiniStat();
    },


    setSMSClick: function (pane) {
        $(pane + ' .smsaction').unbind('click');
        $(pane + ' .smsaction').click(function () {
            var locked, request, smsid;
            event.preventDefault();
            smsid = $(this).parent().parent().parent().parent().attr('data-id');
            locked = $(this).parent().parent().parent().parent().attr('data-locked');
            var request_data = {
                authenticity_token: encodeURIComponent($.authenticityToken()),
                id: smsid,
                locked: locked == 1 ? 0 : 1
            };
            console.log("setSMSClick: ", smsid, locked, request_data);

            var token = $('meta[name="csrf-token"]').attr('content');

            $.ajaxSetup({
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', token);
                }
            });

            request = $.ajax({
                url: '/home/setlocked',
                method: 'POST',
                data: request_data,
                dataType: 'html'
            });
            request.done(function (msg) {
                console.log(msg);
                $.ajax({
                    url: '/home/locked',
                    cache: false
                }).done(function (html) {
                    console.log('done: /home/locked');
                    $('#leftBottomPane').html(html);
                    window.MeSYS.setSMSClick('#leftBottomPane');
                });
                $.ajax({
                    url: '/home/unlocked',
                    cache: false
                }).done(function (html) {
                    if (page == "editor") location.href = location.href;
                    /*
                    window.MeSYS.setMiniStat();
                    console.log('done /home/unlocked');
                    $('#leftTopPane').html(html);
                    window.MeSYS.setSMSClick('#leftTopPane');
                    $('#leftTopPane .smscard').draggable();
                    */
                });
            });
            request.fail(function (jqXHR, textStatus) {
                console.log('Request failed: ' + textStatus);
            });
        });
    },

    setDragtargets: function () {

        // drop empfaenger
        console.log('initialisiere droppable');
        console.log('Zuerst click und dropables löschen');
        jQuery('.kategorycard').droppable({
            accept: '.dragsms',
            hoverClass: 'ui-state-hilight',
            drop: function (event, ui) {
                var smsid;
                smsid = $(ui.draggable).attr('data-id');
                $.ajax({
                    url: '/home/kategorydrop',
                    method: 'POST',
                    data: {
                        authenticity_token: encodeURIComponent($.authenticityToken()),
                        katid: $(this).attr('data-katid'),
                        smsid: smsid
                    },
                    dataType: 'json'
                }).done(function (json) {
                    console.log('done verschieben #sms-id-' + json[0].smsid + ' in kategorie #kat-id-' + json[0].katid);
                    console.log(json);
                    if (json[0].katid !== json[0].katid_from) {
                        $('#numsms-id-' + json[0].katid).text(json[0].numsms);
                        if ($('#numsms-id-' + json[0].katid_from)) {
                            $('#numsms-id-' + json[0].katid_from).text(json[0].numsms_from);
                        }
                        $('#sms-id-' + json[0].smsid).remove();
                        window.MeSYS.setSMSClick('#leftTopPane');
                    } else {
                        console.log('Bitte nicht auf die gleiche Kategorie fallen lassen :)');
                    }
                    window.MeSYS.setMiniStat();
                }).fail(function (jqXHR, textStatus) {
                    console.log('Request failed: ' + textStatus);
                });
            }
        });
        $('.kategorycard').unbind('click');
        $('.kategorycard').click(function () {
            $('#leftTopPane').hide();
            $('#leftBottomPane').hide();
            $('#leftKategoryEditPane').show();
            $.ajax({
                url: '/home/katsms',
                method: 'POST',
                data: {
                    authenticity_token: encodeURIComponent($.authenticityToken()),
                    katid: $(this).attr('data-katid')
                },
                dataType: 'html'
            }).done(function (html) {
                var card1;
                console.log('done laden der SMS Ansicht für den Kategorie-editor');
                $('#leftKategoryEditPane').html(html);

                $('#collapeSMS').unbind();
                $('#uncollapseSMS').unbind();
                $('#uncollapseSMS').on('click', function (e) {
                    console.log('uncollapse sms');
                    $('.collapse').collapse('show');
                });
                $('#collapseSMS').on('click', function (e) {
                    console.log('collapseSMS');
                    $('.collapse').collapse('hide');
                });
                card1 = $('#leftKategoryEditPane .card');
                //materialadmin.AppCard.toggleCardCollapse(card1);
                //materialadmin.App.initialize();
                $('#leftKategoryEditPane .card-head .tools .btn-collapse').on('click', function (e) {
                    var card2;
                    card2 = $(e.currentTarget).closest('.card');
                    //materialadmin.AppCard.toggleCardCollapse(card2);
                });
                $('#closeSmsEdit').unbind('click');
                $('#closeSmsEdit').click(function () {
                    $('#leftKategoryEditPane').hide();
                    $('#leftTopPane').show();
                    $('#leftBottomPane').show();
                });
                $('#leftKategoryEditPane .card').draggable({
                    helper: 'clone',
                    revert: 'invalid',
                    appendTo: '#base'
                });
                $('#leftKategoryEditPane .smsaction').unbind();
                $('#leftKategoryEditPane  .smsaction').click(function () {
                    var katid, smsid;
                    event.preventDefault();
                    smsid = $(this).parent().parent().parent().attr('data-id');
                    katid = $(this).parent().parent().parent().attr('data-katid');

                    var token = $('meta[name="csrf-token"]').attr('content');

                    $.ajaxSetup({
                        beforeSend: function (xhr) {
                            xhr.setRequestHeader('X-CSRF-Token', token);
                        }
                    });
                    $.ajax({
                        url: '/home/katremovesms',
                        method: 'POST',
                        data: 'katid=' + katid + '&smsid=' + smsid,
                        dataType: 'json'
                    }).done(function (json) {
                        var smsid;
                        smsid = json[0].smsid;
                        console.log('done laden der SMS Ansicht für den Kategorie-editor');
                        console.log(json[0]);
                        $('#sms-id-' + smsid).remove();
                        $('#numsms-id-' + json[0].katid).text(json[0].numsms);
                        window.MeSYS.setMiniStat();
                    }).fail(function (jqXHR, textStatus) {
                        console.log('Request failed: ' + textStatus);
                    });
                });
            }).fail(function (jqXHR, textStatus) {
                console.log('Request failed: ' + textStatus);
            });
        });
    },

    setKategoryClick: function () {
        console.log('Set Click: kategoryAddSubmit');
        $('#kategoryAddSubmit').unbind();
        $('#kategoryAddSubmit').click(function (event) {
            event.preventDefault();
            console.log("XXXXXXXXXXXXXXXX kategoryAddSubmit klicked");
            window.MeSYS.submitted = 0;
            $('#kategoryAddForm').submit();
        });
    },
    setKategoryForm: function () {
        console.log('Set submit: kategoryAddSubmit');
        $('#kategoryAddForm').unbind();
        $('#kategoryAddForm').submit(function (e) {
            console.log('Form submitted');
            var url;
            url = '/kategories.json';
            //console.log('before AJAX ' + url);
            //console.log($('#kategoryAddForm').serialize());
            $.ajax({
                type: 'POST',
                url: url,
                data: $('#kategoryAddForm').serialize(),
                success: function (data) {
                    console.log('kategory angelegt - return result:', data);
                    location.href = location.href;

                }
            }).fail(function (e) {
                console.log(e);
            });
            //console.log('after ajax');
            e.preventDefault();
        });
    }
}
// namespace ende
$(document).ready(function () {

    console.log("page: ", page);
    if (page === "tagcloud") {
        window.MeSYS.getWordCloud();
    }
    else if ((page === "home") || (page === "editor")) {
        console.log("document.ready fired");
        console.log("setDragtargets");
        window.MeSYS.setDragtargets();
        console.log("setKategoryForm");
        window.MeSYS.setKategoryForm();

        console.log("document.load fired");
        /*Editorpanel Javascript */
        $.authenticityToken = function () {
            return $('#csrf-token').attr('content');
        };
        window.MeSYS.setSMSClick('#leftTopPane');
        window.MeSYS.setSMSClick('#leftBottomPane');
        $('#leftTopPane .smscard').draggable({
            helper: 'clone',
            revert: 'invalid',
            appendTo: '#base'
        });
        window.MeSYS.setKategoryClick();
        window.MeSYS.setSchnellsuche();
        window.MeSYS.setMiniStat();

    }
    else if (page === "filter") {
        window.MeSYS.setMessageSchnellsuche();
        $('.schnellsucheInput').focus().click();
    }
    /* smsmode setup */
    else if (page === "smsmode") {

        $('#smssslection').unbind('change');
        $('#smssslection').on('change', function () {
            console.log("unset select");
            $('option:selected', $('select#katselection')).prop("selected", false);
            $('#selectedDisplay').html('');

        });

        $('#katselection').unbind('change');
        $('#katselection').on('change', function () {
            console.log("unset input box");
            $('#smssslection').val('');
            $('#selectedDisplay').html('');
        });


        $('#katselform').unbind('submit');
        $('#katselform').submit(function (e) {
            e.preventDefault();

            var katselect = $('option:selected', $('#katselection')).val()
            var smsnr = $('#smssslection').val()
            if (katselect === "") katselect = undefined;
            var displaytype = (katselect !== undefined ? 'kat' : 'sms');

            var data = { displaytype: displaytype, smsnr: smsnr, katselect: katselect };
            console.log("data=", data)
            console.log("data=", JSON.stringify(data))
            console.log("data=", JSON_to_URLEncoded(data))
            console.log('katselform submit data:', data);
            var token = $('meta[name="csrf-token"]').attr('content');

            $.ajaxSetup({
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('X-CSRF-Token', token);
                }
            });

            $.post('/smsmode/setfsel', JSON_to_URLEncoded(data), function (json) {
                // SMS/Kategorie Daten vom API laden
                console.log("setfsel: ", json);
                var payload = JSON.parse(json[0]['payload']);
                console.log("setfsel payload: ", payload);
                var targetmethod = '/smsmode/show' + json[0].name;
                console.log('calling ' + targetmethod);
                $.get(targetmethod + '?id=' + payload[0].displayrecord[0].id, function (data) {
                    $('#selectedDisplay').html(data);
                });
            }, 'json').done(function (data) {
                console.log('setfsel successfully called');
            }).fail(function (data) {
                console.log('setfsel call failed', data);
            });

            /*		$.ajax({
                      url: '/smsmode/setfsel',
                      method: 'POST',
                      data: data,
                      dataType: 'json',
                      success: 'katselSuccess'
                    });
            */
            window.MeSYS.setMiniStat();

        });
    }
    /* smsview setup */
    else if (page === "smsview") {
        //        setTimeout(function (e) {
        var parser = document.createElement('a');
        parser.href = location.href;
        console.log("parser-hash: " + parser.hash);

        if (parser.hash == "#kategorie-tab") {
            $('#kategorie-tab').trigger('click');
        } else if (parser.hash == "#nachrichten-tab") {
            $('#nachrichten-tab').trigger('click');
        }
        //       }, 100);

        var card2 = $('#first2 .card');
        if (page == "smsview") {
            $('#first2 .card').on("click", function (event) {
                window.console.log("card clicked", $(this).html());
                // daten werden aus card geholt, da fehlt das card-div, daher manuell
                // dazumachen
                // muss genau ins gleiche div rein, wie die kategorie, sonst passt
                // dann die hoehe etc nicht mehr, oder die nachricht wird nicht angezeigt
                // Bugfix
                $('#fullscreenblack #katsms').html('<div class="card ">' + $(this).html() + '</div>')
                $('#fullscreenblack #katsms').attr('style', '');
                $('#nachrichtDetail').show();
                //$("#smsview #content").css('filter', 'blur(3px)');
                $('#fullscreenblack .card .btn').unbind();
                $('#fullscreenblack .card .btn').on("click.rails", function (event) {
                    $('#nachrichtDetail').hide();
                    $("#content").css('filter', 'none');
                    event.preventDefault();
                });
                event.preventDefault();
            });
            // kategorie spezial
            $('#second2 .card').on('click', function (e) {
                var katid = $(this).attr('kat-id');
                $.ajax({
                    url: '/tabletview/katnachrichten',
                    data: { katid: katid },
                    type: 'GET'
                }).done(function (html) {
                    $('#katsms').html(html);
                    setTimeout(function () {
                        var newHeight = window.innerHeight - 200;
                        console.log("HOEHE:", newHeight, $('#katsms >div').height());
                        if ($('#katsms > div').height() > newHeight) {
                            $('#katsms').css('height', newHeight + 'px');
                        } else {
                            $('#katsms').css('height', $('#katsms > div').height());
                        }
                    }, 100);
                    $('#katsms').css('overflow-y', 'scroll');
                    $('#nachrichtDetail').show();

                    $('#katsms > div > div.card-head > div > div > a').on('click.rails', function () {
                        $('#nachrichtDetail').hide();
                        $('#nachrichtDetail #katsms').html('');
                    });
                });
                e.preventDefault();
            });

            // kategorie ende

        }
    }
    else if (page === "tabletview") {
        var card1 = $('#first2 .card');

        $('#first2 .card-head .tools .btn-collapse').on('click', function (e) {
            var card2;
            card2 = $(e.currentTarget).closest('.card');
        });
        $('.kategoryClass').on('click.rails', function (e) {
            var katid = $(this).attr('kat-id');
            $.ajax({
                url: '/tabletview/katnachrichten',
                data: { katid: katid },
                type: 'GET'
            }).done(function (html) {
                $('#katsms').html(html);

                setTimeout(function () {
                    var newHeight = window.innerHeight - 200;
                    console.log("HOEHE:", newHeight, $('#katsms >div').height());
                    if ($('#katsms > div').height() > newHeight) {
                        $('#katsms').css('height', newHeight + 'px');
                    } else {
                        $('#katsms').css('height', $('#katsms > div').height());
                    }
                }, 100);
                $('#katsms').css('overflow-y', 'scroll');
                $('#nachrichtDetail').show();

                $('#katsms > div > div.card-head > div > div > a').on('click.rails', function () {
                    $('#nachrichtDetail').hide();
                    $('#nachrichtDetail #katsms').html('');
                });

            });
            e.preventDefault();
        });
    }
});
